<template>
  <div>
    <br />
    <div class="form-container">
      <b-row>
        <b-col md="auto">
          <label>
            Category
          </label>
        </b-col>

        <b-col md="auto">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="formData.category"
            :options="categories"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-col>
      </b-row>
      <br />
      <label>Name</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.name"
      ></b-form-input
      ><br /><br />

      <label>Description</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.description"
      ></b-form-input
      ><br /><br />

      <label>Type</label> <br />
      <b-form-select
        :options="types"
        v-model="formData.type"
        class="w-auto"
      ></b-form-select>
      <br /><br />

      <label>Video</label><br />
      <b-form-input
        placeholder=".."
        v-model="formData.video"
      ></b-form-input
      ><br /><br />

      <b-row class="mt-4-5">
        <b-col md="auto">
          <label>Thumbnail</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__top">
                <img :src="formData.thumbnail.src" alt="img" style="max-height:108px" />
                <div class="img-remove" @click="removeThumbnail()" v-if="formData.thumbnail.src !='/img/thumbnail.png'">
                  X Remove
                </div>
              </div>

              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="file-image"
                  id="file-image"
                  class="hidden-input"
                  @change="uploadThumbnail($event)"
                />
                <label for="file-image" class="file-upload">
                  <fa-icon icon="upload" class="my-icon" />
                  Change Thumbnail
                </label>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="auto">
          <label>Resource</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="resource"
                  id="resource"
                  class="hidden-input"
                  @change="uploadResource($event)"
                />
                <label for="resource" class="file-upload">
                  <fa-icon icon="upload" class="mr-1" />
                  Change Resource
                </label><br /> 
                {{ formData.resource.name }}
              </div><br />
              <div class="upload-image__bottom" v-if="formData.oldresource.raw">
                <label class="file-upload mr-1" @click="downlaodResource(formData.oldresource.raw)">
                  <fa-icon icon="download" class="mr-1" />
                  Download Uploaded Resource
                </label>
                <label
                  class="file-upload"
                  @click="removeResource"
                >
                  <fa-icon icon="trash-alt" class="mr-1" />
                </label>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <my-button type="blue" size="lg" block class="mt-4-5" rounded v-if="!showLoading" @click="save">
        SIMPAN
      </my-button>
      <my-button type="blue" size="lg" block class="mt-4-5" rounded disabled v-else>
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>

    </div>

    <div class="form-container">
      <h4 class="color3 mt-5">
        MARKETING ITEM
      </h4>

      <div class="course-collection" v-if="item.length > 0">
        <div v-for="(it, i) in item">
          <hr v-if="i != 0 && i < item.length"/>
          <div class="space-between align-start">
            <div class="flex-1">
              <div class="align-start">
                <p class="flex-1 mr-2">
                  {{ it.name }}
                </p>
              </div>
            </div>

            <div class="align-center">
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="editItem(i)"
              >
                <fa-icon icon="pencil-alt" />
              </b-button>
              <b-button
                size="sm"
                class="mr-2 action-button"
                variant="outline-secondary"
                @click="handleDelete(it.id)"
              >
                <fa-icon icon="trash-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <my-button type="orange" size="sm" rounded class="mt-2" @click="newItem">
        <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
        ADD NEW ITEM
      </my-button>
    </div>

    <b-modal
      v-model="contentModal"
      id="contentModal"
      :title="currentItem.title"
      hide-footer
      centered
    >
      <div class="content-modal-container">
        <label>Name</label><br />
        <b-form-input
          placeholder=".."
          v-model="currentItem.name"
        ></b-form-input
        ><br /><br />
        <label>Color</label><br />
        <b-form-input
          placeholder=".."
          v-model="currentItem.color"
        ></b-form-input
        ><br /><br />
        <b-row class="mt-4-5">
          <b-col md="auto">
            <label>Thumbnail</label><br />
            <div class="upload-image">
              <div class="upload-image__item">
                <div class="upload-image__top">
                  <img :src="currentItem.thumbnail.src" alt="img" style="max-height:108px" />
                  <div class="img-remove" @click="removeThumbnail('item')" v-if="currentItem.thumbnail.src !='/img/thumbnail.png'">
                    X Remove
                  </div>
                </div>

                <div class="upload-image__bottom">
                  <input
                    type="file"
                    name="file-image-item"
                    id="file-image-item"
                    class="hidden-input"
                    @change="uploadThumbnailItem($event)"
                  />
                  <label for="file-image-item" class="file-upload">
                    <fa-icon icon="upload" class="my-icon" />
                    Upload Thumbnail
                  </label>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="auto">
            <label>Resource</label><br />
            <div class="upload-image">
              <div class="upload-image__item">
                <div class="upload-image__top">
                  <img :src="currentItem.resource.src" alt="img" style="max-height:108px" />
                  <div class="img-remove" @click="removeResource('item')" v-if="currentItem.resource.src !='/img/thumbnail.png'">
                    X Remove
                  </div>
                </div>

                <div class="upload-image__bottom">
                  <input
                    type="file"
                    name="file-item-resource"
                    id="file-item-resource"
                    class="hidden-input"
                    @change="uploadResourceItem($event)"
                  />
                  <label for="file-item-resource" class="file-upload">
                    <fa-icon icon="upload" class="my-icon" />
                    Upload Resource
                  </label>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="justify-end mt-4 mb-3">
          <my-button
            type="outline-blue"
            size="lg"
            class="mr-2"
            @click="contentModal = false"
          >
            CANCEL
          </my-button>

          <my-button type="blue" size="lg" @click="saveItem" v-if="!showLoading">
            SAVE
          </my-button>
          <my-button type="blue" size="lg" v-else disabled>
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {  
      contentModal: false,
      showLoading: false,
      formData: {
        name: '',
        description: '',
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        resource: {
          data: null,
          name: "",
          raw: ""
        },
        oldresource: {
          name: "",
          raw: ""
        },
        type: 1,
        video: "",
        category: [],
        deleteThumbnail: 0,
        deleteResource: 0
      },
      types: [],
      categories:[],
      currentItem:{
        id: null,
        name: "",
        title: "",
        color: "",
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        resource: {
          data: null,
          src: "/img/thumbnail.png",
          raw: ""
        },
        deleteResource: 0,
        deleteThumbnail: 0
      },
      item:[],
    };
  },

  computed: {
    ...mapGetters({
      token: types.TOKEN
    })
  },
  
  methods: {
    ...mapActions({
      updateMarketingKit: types.UPDATE_MARKETING_KIT,
      getTypeList: types.GET_MARKETING_TYPE_LIST,
      getCategoryList: types.GET_MARKETING_CATEGORY_LIST,
      getMarketingKit: types.GET_MARKETING_KIT_DETAIL,
      createMarketingItem: types.CREATE_MARKETING_ITEM,
      updateMarketingItem: types.UPDATE_MARKETING_ITEM,
      deleteItem:types.DELETE_MARKETING_ITEM
    }),
    getDetail() {
      this.getMarketingKit({id:this.$route.params.id})
      .then(response => {
        this.formData.name = response[0].name;
        this.formData.description = response[0].description;
        this.formData.type = response[0].type;
        this.formData.video = response[0].video;
        for (var i = 0; i < response[0].category.length; i++) {
          this.formData.category.push(response[0].category[i].id);
        }
        this.formData.thumbnail.raw = response[0].thumbnail;
        this.formData.oldresource.raw = response[0].resource ? response[0].resource : "";
        this.formData.thumbnail.src = response[0].thumbnail ? response[0].thumbnail_url : '/img/thumbnail.png';
        this.formData.oldresource.name = response[0].resource_url ? response[0].resource_url : "";
        this.item = response[0].item;
      })
      .catch(error => {
        this.toastError('b-toaster-top-center', error);
      })
    },
    save() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("id", this.$route.params.id);
      newFormData.append("description", this.formData.description);
      newFormData.append("name", this.formData.name);
      newFormData.append("type", this.formData.type);
      newFormData.append("video", this.formData.video);
      newFormData.append("thumbnail", this.formData.thumbnail.data);
      newFormData.append("resource", this.formData.resource.data);
      newFormData.append("deleteThumbnail", this.formData.deleteThumbnail);
      newFormData.append("deleteResource", this.formData.deleteResource);
      for (var i = 0; i < this.formData.category.length; i++) {
        newFormData.append("category"+i, this.formData.category[i]);
      }
      this.updateMarketingKit(newFormData)
        .then(response => {
          this.showLoading = false;
          this.toastSuccess('b-toaster-top-center', "Updated");
          this.getDetail();
        })
        .catch(error => {
          this.showLoading = false;
          this.toastError('b-toaster-top-center', error);
        });
    },
    toastSuccess(toaster, message, append = false) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    removeThumbnail(data){
      if(data == 'item') {
        this.currentItem.thumbnail.data = null;
        this.currentItem.thumbnail.src = '/img/thumbnail.png';
        this.currentItem.deleteThumbnail = 1;
      } else {
        this.formData.thumbnail.data = null;
        this.formData.thumbnail.src = '/img/thumbnail.png';
        this.formData.deleteThumbnail = 1;
      }
    },
    removeResource(data){
      if(data == 'item') {
        this.currentItem.resource.data = null;
        this.currentItem.resource.src = '/img/thumbnail.png';
        this.currentItem.deleteResource = 1;
      } else {
        this.formData.resource.name = "";
        this.formData.deleteResource = 1;
      }
    },
    uploadThumbnail(event) {
      var fileData = event.target.files[0];
      this.formData.thumbnail.data = fileData;
      this.formData.thumbnail.src = URL.createObjectURL(fileData);
    },
    uploadResource(event) {
      var fileData = event.target.files[0];
      this.formData.resource.data = fileData;
      this.formData.resource.name = fileData.name;
    },
    uploadThumbnailItem(event) {
      var fileData = event.target.files[0];
      this.currentItem.thumbnail.data = fileData;
      this.currentItem.thumbnail.src = URL.createObjectURL(fileData);
    },
    uploadResourceItem(event) {
      var fileData = event.target.files[0];
      this.currentItem.resource.data = fileData;
      this.currentItem.resource.src = URL.createObjectURL(fileData);
    },
    typeList(level_id = null) {
      this.getTypeList()
        .then(response => {
          var res = response;
          this.types = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name
            };
            this.types.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    categoryList() {
      this.getCategoryList()
        .then(response => {
          var res = response;
          this.categories = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name,
            };
            this.categories.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    downlaodResource(filename) {
      console.log(filename);
      filename = filename.split('mkit/').join("");
      var link =
        types.API_BASE_URL + "download/" + filename + "?token=" + this.token;
      window.open(link);
    },
    newItem() {
      this.currentItem = {
        id: null,
        name: "",
        title: "Add New Item",
        color: "",
        thumbnail: {
          src: "/img/thumbnail.png",
          data: null,
          raw: ""
        },
        resource: {
          data: null,
          src: "/img/thumbnail.png",
          raw: ""
        },
      }
      this.contentModal = true;
    },
    saveItem() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("kit_id", this.$route.params.id);
      newFormData.append("name", this.currentItem.name);
      newFormData.append("color", this.currentItem.color);
      newFormData.append("thumbnail", this.currentItem.thumbnail.data);
      newFormData.append("resource", this.currentItem.resource.data);
      if(this.currentItem.id) {
        newFormData.append("id", this.currentItem.id);
        newFormData.append("deleteThumbnail", this.currentItem.deleteThumbnail);
        newFormData.append("deleteResource", this.currentItem.deleteResource);
        this.updateMarketingItem(newFormData)
          .then(response => {
            this.showLoading = false;
            this.toastSuccess('b-toaster-top-center', "Item updated");
            this.contentModal = false;
            this.getDetail();
          })
          .catch(error => {
            this.showLoading = false;
            this.toastError('b-toaster-top-center', error);
          });
      } else {
        this.createMarketingItem(newFormData)
          .then(response => {
            this.showLoading = false;
            this.toastSuccess('b-toaster-top-center', "New item added");
            this.contentModal = false;
            this.getDetail();
          })
          .catch(error => {
            this.showLoading = false;
            this.toastError('b-toaster-top-center', error);
          });
      }
    },
    editItem(idx) {
      this.currentItem = {
        id: this.item[idx].id,
        name: this.item[idx].name,
        title: "Edit Item",
        color: this.item[idx].color,
        thumbnail: {
          src: this.item[idx].thumbnail ? this.item[idx].thumbnail_url : "/img/thumbnail.png",
          data: null,
          raw: this.item[idx].thumbnail
        },
        resource: {
          data: null,
          src: this.item[idx].resource ? this.item[idx].resource_url : "/img/thumbnail.png",
          raw: this.item[idx].resource
        },
      }
      this.contentModal = true;
    },
    handleDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Delete item?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteItem({id:idx})
            .then(response => {
              this.toastSuccess('b-toaster-top-center', "Item dihapus");
              this.getDetail();
            })
            .catch(error => {
              this.toastError('b-toaster-top-center', error);
            })
          }
        })
        .catch(err => {
          this.toastError('b-toaster-top-center', err);
        });
    }
  },

  mounted() {
    this.getDetail();
    this.typeList();
    this.categoryList();
  }
};
</script>

<style>

.button-ya {
  border: 1px solid #3bbaed;
  color: #3bbaed;
  margin-right: 11px;
  background: #fff;
  min-width: 22px;
  margin-top: 11px;
}

@media (min-width: 576px) {
  #modal-icon .modal-lg {
    max-width: 544px;
  }
 
}

.content-modal-container {
  padding: 20px 44px;
}

@media screen and (max-width: 768px) {
  .content-modal-container {
    padding: 20px 0;
  }
}

#contentModal .modal-dialog {
  width: 708px;
  max-width: 95%;
}
</style>
